body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.groupInputSale {
  display: flex;
  line-height: 30px;
  align-items: center;
  position: relative;
}

.iconInputSale {
  position: absolute;
  left: 1rem;
  fill: none;
  width: 1rem;
  height: 1rem;
}

.inputSale {
  width: 100%;
  height: 45px;
  line-height: 30px;
  padding-left: 12px;
  border: 2px solid transparent;
  outline: none;
  background-color: #f4f4f4;
  color: #4b4b4b;
  transition: 0.5s ease;
  border-width: 2px;
  border-color: #34C576;
  font-weight: 530;
  font-size: 13px;
}

.inputSale::placeholder {
  color: #94a3b8;
}

.inputSale:focus,
input:hover {
  outline: none;
  border-color: #34C576;
  background-color: #fff;
  box-shadow: 0 0 0 5px rgb(52 197 118 / 30%);
}

.iconInputHide {
  position: relative;
  right: 2.5rem;
  fill: none;
  width: 1rem;
  height: 1rem;
  opacity: 0.5;
}

.linkText:hover {
  color: #34C576;
  cursor: pointer;
}

.cardErroLogin {
  width: 100%;
  max-width: 290px;
  height: 70px;
  background: #353535;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: left;
  backdrop-filter: blur(10px);
  transition: 0.5s ease-in-out;
  padding-bottom: 12px;
}

.cardErroLogin:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.imgErroLogin {
  width: 45px;
  height: 45px;
  margin-left: 10px;
  border-radius: 10px;
}

.cardErroLogin:hover > .imgErroLogin {
  transition: 0.5s ease-in-out;
}

.textBoxErroLogin {
  width: calc(100% - 90px);
  margin-left: 15px;
  color: white;
  font-family: "Poppins" sans-serif;
  height: 70px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.textContentErroLogin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -17px;
}

.h1ErroLogin {
  font-size: 16px;
  font-weight: bold;
}

.spanErroLogin {
  font-size: 10px;
}

.pErroLogin {
  font-size: 12px;
  font-weight: lighter;
}

.erroStep0 {
  top: 0;
  right: -400px;
}

.erroStep1 {
  top: 0;
  right: -400px;
  animation: moveErroLog 0.5s forwards;
}
.erroStep2 {
  top: 0;
  right: 0px;
  animation: moveErroLogClose 0.5s forwards;
}

@keyframes moveErroLog {
  from {
    right: -400px;
  }

  to {
    right: 0px;
  }
}
@keyframes moveErroLogClose {
  from {
    right: 0px;
  }

  to {
    right: -400px;
  }
}

/*Short Loading*/

.loader {
  width: 80px;
  height: 50px;
  position: relative;
}

.loader-text {
  position: absolute;
  top: 0;
  padding: 0;
  margin: 0;
  color: #34C576;
  animation: text_713 3.5s ease both infinite;
  font-size: .8rem;
  letter-spacing: 1px;
}

.load {
  background-color: #219f59;
  border-radius: 50px;
  display: block;
  height: 16px;
  width: 16px;
  bottom: 0;
  position: absolute;
  transform: translateX(64px);
  animation: loading_713 3.5s ease both infinite;
}

.load::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #34C576;
  border-radius: inherit;
  animation: loading2_713 3.5s ease both infinite;
}

@keyframes text_713 {
  0% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }

  40% {
    letter-spacing: 2px;
    transform: translateX(26px);
  }

  80% {
    letter-spacing: 1px;
    transform: translateX(32px);
  }

  90% {
    letter-spacing: 2px;
    transform: translateX(0px);
  }

  100% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }
}

@keyframes loading_713 {
  0% {
    width: 16px;
    transform: translateX(0px);
  }

  40% {
    width: 100%;
    transform: translateX(0px);
  }

  80% {
    width: 16px;
    transform: translateX(64px);
  }

  90% {
    width: 100%;
    transform: translateX(0px);
  }

  100% {
    width: 16px;
    transform: translateX(0px);
  }
}

@keyframes loading2_713 {
  0% {
    transform: translateX(0px);
    width: 16px;
  }

  40% {
    transform: translateX(0%);
    width: 80%;
  }

  80% {
    width: 100%;
    transform: translateX(0px);
  }

  90% {
    width: 80%;
    transform: translateX(15px);
  }

  100% {
    transform: translateX(0px);
    width: 16px;
  }
}
 
 
.iconSvgBar svg{
  width:  30%;
  height: 30%;
}


.iconSvgBar:hover path{
  fill: #34C576;
}